
import Vue from "vue";
import { Transaction } from "@/types/interfaces";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "TransactionModal",
  props: {
    transaction: {
      type: Object as () => Transaction
    }
  },
  data() {
    return {
      paidMessages: {
        paid: "Total Received",
        reimbursed: "Total Paid",
        withdrawal: "Amount Withdrawn",
        topUp: "Amount Added"
      } as any,
      refundMessage: "Total Refunded",
      // Transaction details items
      transactionDetails: {
        paid: {
          subject: {
            label: "Mentoring type",
            icon: "mentorice"
          },
          transaction_date: {
            label: "Transaction date",
            icon: "calendar"
          },
          id: {
            label: "Transaction ID",
            icon: "id-card"
          },
          paid_from: {
            label: "Paid from",
            icon: "profile"
          },
          paid_to: {
            label: "Paid to",
            icon: "profile"
          },
          payment_method_type: {
            label: "Paid with",
            icon: "credit-card-alt"
          },
          mentorice_fee: {
            label: "Mentorice fee",
            icon: "fee",
            percent: true
          },
          donations: {
            label: "Donations ",
            icon: "donations",
            percent: true
          },
          zoom_fee: {
            label: "Mentorice host fee",
            icon: "zoom",
            percent: true,
            condition: () => this.transaction?.zoom_fee
          }
        },
        reimbursed: {
          subject: {
            label: "Mentoring type",
            icon: "mentorice"
          },
          transaction_date: {
            label: "Transaction date",
            icon: "calendar"
          },
          id: {
            label: "Transaction ID",
            icon: "id-card"
          },
          paid_from: {
            label: "Paid from",
            icon: "profile"
          },
          paid_to: {
            label: "Paid to",
            icon: "profile"
          },
          payment_method_type: {
            label: "Paid with",
            icon: "credit-card-alt"
          },
          donations: {
            label: "Donations ",
            icon: "donations",
            percent: true
          }
        },
        withdrawal: {
          transaction_date: {
            label: "Transaction date",
            icon: "calendar"
          },
          id: {
            label: "Transaction ID",
            icon: "id-card"
          },
          "withdrawal_request.method_type": {
            label: "Withdrawn to",
            icon: "transfer",
            class: "text-primary capitalize"
          },
          "withdrawal_request.status": {
            label: "Request Status",
            icon: "status-chart",
            class: "text-primary capitalize"
          }
        },
        topUp: {
          transaction_date: {
            label: "Transaction date",
            icon: "calendar"
          },
          id: {
            label: "Transaction ID",
            icon: "id-card"
          },
          balance: {
            label: "Top-up to",
            icon: "dollar-price",
            class: "text-primary capitalize",
            value: "Your balance"
          },
          reason: {
            label: "Reason",
            icon: "chat",
            class: "text-sm capitalize font-size-12"
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    }),
    getTransaction: function(): any {
      if (this.transaction?.is_refunded) {
        //@ts-ignore
        delete this.transactionDetails[this.transaction.type].donations;
      }
      //@ts-ignore
      return this.transactionDetails[this.transaction.type];
    }
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    getTotalPaidMessage(transaction: Transaction) {
      if (transaction.is_refunded) {
        return this.refundMessage;
      }
      return this.paidMessages[transaction.type];
    },
    numberWithCommas(number: any) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onViewInvoice() {
      this.$store
        .dispatch("wallet/GENERATE_INVOICE", this.transaction.id)
        .then(data => {
          console.log(data);
          const type =
            this.transaction.booking_type == "Mentorcast Booking" ? "M" : "S";
          const name = `MR${this.transaction.id}-${this.transaction.user_id}-${type}.pdf`;
          this.downloadURI(data, name);
        });
    },
    downloadURI(data: any, name: any) {
      const blob = new Blob([data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
    },
    resolveDynamic(path: any, obj: any) {
      return path.split(".").reduce(function(prev: any, curr: any) {
        return prev ? prev[curr] : null;
      }, obj || self);
    }
  }
});
